import * as React from 'react';
import { Link, Navigate, useLocation } from 'react-router-dom';

import * as Yup from 'yup';

import { AuthResult, useApplicationContext } from '@common/react/components/Core/Application/Application';
import { BaseInit } from '@common/react/objects/BaseInit';
import { simpleStringValidator } from '@common/react/utils/validationHelpers';
import FormikField from '@common/react/components/Forms/FormikField/FormikField';
import { BaseUser } from '@common/typescript/objects/BaseUser';
import PhoneOrEmailInput, { emailOrPhoneValidator } from '@common/react/components/Forms/PhoneOrEmailInput/PhoneOrEmailInput';
import ServerPageProvider from '@common/react/components/Core/ServerPageProvider/ServerPageProvider';
import { ItemProvider } from '@common/react/components/Core/ItemProvider/ItemProvider';
import { ItemEditor } from '@common/react/components/Core/ItemEditor/ItemEditor';
import { callWithConnectionOnce } from '@common/react/utils/configureSignarR';
import { WithId } from '@common/typescript/objects/WithId';
import SendCodeButton from '@common/react/components/Pages/Login/SendCodeButton';

import '@common/react/scss/components/login.scss';

export interface LoginFormValues extends WithId {
	login: string;
	password: string;
	code: string;
	path: string;
}

const validationSchema = Yup.object().shape({
	login: emailOrPhoneValidator,
	password: simpleStringValidator,
});

export interface AuthCodeComponentProps<User extends BaseUser = BaseUser, TInit extends BaseInit<User> = BaseInit<User>> {
	formValues: LoginFormValues;
	afterSubmit: (item: LoginFormValues, res: AuthResult<User, TInit>) => void;
	cancelAuthWithCode: () => void;
}

interface LoginProps<User extends BaseUser, TInit extends BaseInit<User>> {
	containerClassName?: string;
	afterBaseRedirectUser?: (user: User) => React.ReactNode;
	renderAuthWithCode?: (props: AuthCodeComponentProps) => React.ReactNode;
	logo?: React.ReactNode;
	withFieldTitle?: boolean;
	title?: string;
	withoutServerPage?: boolean;
	request?: string;
	afterSubmit?: (item: LoginFormValues, res: AuthResult<User, TInit>) => void;
	onSaveRequestError?: ((error: string) => void);
	buttons?: React.ReactNode;
	afterLogin?: () => void;
	recoverPath?: string;
	authByCodeRequest?: string;
	sendCodeRequest?: string;
}

const Login: <User extends BaseUser, TInit extends BaseInit<User> = BaseInit<User>>(p: LoginProps<User, TInit>) =>
React.ReactElement<User> = <User extends BaseUser, TInit extends BaseInit<User> = BaseInit<User>>(props) => {
	const search = useLocation().search;

	const [isVisible, setIsVisible] = React.useState<boolean>(false);
	const [openLoginCode, setOpenLoginCode] = React.useState<boolean>(false);
	const [formValues, setFormValues] = React.useState<LoginFormValues | null>(null);

	const { getUser, getHostOptions, handleLogin } = useApplicationContext();
	const user = getUser<User>();
	const hostOptions = getHostOptions();

	const {
		afterBaseRedirectUser,
		renderAuthWithCode,
		withFieldTitle,
		containerClassName = '',
		logo,
		buttons,
		title = 'Login',
		withoutServerPage,
		request = 'auth',
		recoverPath = '/recover',
		afterSubmit = (item, res) => {
			handleLogin(res?.initObject as TInit);

			setFormValues(null);
		},
		onSaveRequestError = (error) => {
			if (error === '2FA enabled' && renderAuthWithCode) {
				setIsVisible(true);

				return true;
			}

			if (error === 'The confirmation code has been sent.') {
				setOpenLoginCode(true);

				return true;
			}
		},
		afterLogin,
		authByCodeRequest = 'authByCode',
		sendCodeRequest,
	} = props;

	const [afterLoginTrigger, setLoginTrigger] = React.useState(false);

	React.useEffect(() => {
		if (user && !afterLoginTrigger) {
			afterLogin && afterLogin();
			setLoginTrigger(true);
		}
	}, [!!user]);

	const redirectUser = React.useCallback((user: User) => {
		if (hostOptions?.redirectUrlName) {
			const redirectUrl = new URLSearchParams(search).get(hostOptions.redirectUrlName);
			if (redirectUrl) {
				return <Navigate to={redirectUrl} />;
			}
		}

		if (afterBaseRedirectUser) {
			return afterBaseRedirectUser(user);
		}

		return <Navigate to="/dashboard" />;
	}, [afterBaseRedirectUser]);

	const beforeSubmit = (values, actions, submit) => {
		setFormValues(values);

		callWithConnectionOnce(() => { submit(); });
	};

	const cancelAuthWithCode = () => {
		setIsVisible(false);
	};

	return (<>
		{!withoutServerPage
				&& <ServerPageProvider
					path="login"
					defaultTitle="Login"
					loader={<></>}
					inner={(serverPage) => <></>}
				/>
		}
		<div className={containerClassName}>
			{logo}
			<div className="enter-page__container">
				<ItemProvider<LoginFormValues>
					id={-1}
					type={request}
					readonly={false}
					add={{
						login: '',
						password: '',
						code: '',
						path: '/',
					}}
					validationSchema={validationSchema}
					onSaveRequestError={onSaveRequestError}
				>
					{renderAuthWithCode && isVisible && !user
						? renderAuthWithCode({ formValues, afterSubmit, cancelAuthWithCode })
						: <div className="enter-page__form general-form">
							{user && redirectUser(user)}
							{title && <div className="site-headline text-center">
								<h1>{title}</h1>
							</div>}
							<ItemEditor
								withButtons
								successMessage=""
								buttons={buttons}
								saveText="Login"
								beforeSubmit={beforeSubmit}
								afterSubmit={afterSubmit}
								edit={(formikBag) => <>
									<FormikField
										fieldName="login"
										title={withFieldTitle ? 'Email or Phone number*' : ''}
										containerClassName="form-group"
										render={(fieldProps, inputProps) => <>
											<PhoneOrEmailInput
												fieldProps={fieldProps}
												className="form-control"
												placeholder={withFieldTitle ? '' : 'Email or Phone number*'}
												{...inputProps}
											/>
											{authByCodeRequest && fieldProps.field.value
												&& <SendCodeButton
													phone={fieldProps.field.value}
													afterSubmit={afterSubmit}
													authRequest={authByCodeRequest}
													sendCodeRequest={sendCodeRequest}
													openModal={openLoginCode}
												/>
											}
										</>}
									/>
									<FormikField
										fieldName="password"
										title={withFieldTitle ? 'Password*' : ''}
										inputProps={{ placeholder: withFieldTitle ? '' : 'Password*', type: 'password' }}
										containerClassName="form-group"
									/>
									{recoverPath && <div className="clearfix mb10">
										<div className="pull-right">
											<Link to={recoverPath}>Forget Password?</Link>
										</div>
									</div>}
								</>}
							/>
						</div>
					}
				</ItemProvider>
			</div>
		</div>
	</>
	);
};

export default Login;
